import './About.css';
import { useState } from 'react';
import NavigationBar from '../navbar/NavigationBar';
import Container from 'react-bootstrap/Container';
import { ReactMarkdownWithPlugins } from "./../shared/ReactMarkdownWithPlugins.js";
import { Collapse, Button } from 'react-bootstrap';

function About() {
    const [aboutContent, setAboutContent] = useState(null);
    const [aboutAppOpen, setAboutAppOpen] = useState(false);
    const [funcRequirementsOpen, setFuncRequirementsOpen] = useState(false);
    const [nonFuncRequirementsOpen, setNonFuncRequirementsOpen] = useState(false);

    fetch(process.env.REACT_APP_ABOUT_CONTENT_API_URL)
        .then(response => {
            if (response.ok) {
                response.text().then(message => setAboutContent(message));
            } else {
                response.text().then(message => {
                    if (message) {
                        console.log(message);
                    }
                });
            }
        })
        .catch(error => {
            console.log(error);
        });

    return (
        <>
            <div className="about-bg">
                <NavigationBar></NavigationBar>
                <Container style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <div className='text-center' style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                        <iframe
                            src="https://www.youtube.com/embed/buTr-t1vHVQ"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Inspiring & Uplifting Background Music"
                        />
                    </div>

                    <div className="text-center about-title-font mt-3">
                        <Button
                            style={{ border: "double 4px #6c757d", borderRadius: "10px", padding: "8px 14px" }}
                            variant="outline-dark" onClick={() => { setFuncRequirementsOpen(!funcRequirementsOpen) }}>
                            Functional Requirements
                        </Button>
                    </div>
                    <Collapse in={funcRequirementsOpen}>
                        <div style={{
                            marginTop: "1rem",
                            background: "rgba(255, 255, 255, .45)",
                            padding: "20px",
                            borderRadius: "20px",
                            fontFamily: 'Sour Gummy, sans-serif'
                        }}>
                            <ul>
                                <li><a href='/blogs'>Blogs</a></li>
                                <ul>
                                    <li>This page should display list of all <b>categories</b> containing blogs pertaining to that category, along with <b>total blog count</b> for each category.</li>
                                </ul>
                                <li><a href='/latestblogs'>BlogsRecent</a></li>
                                <ul>
                                    <li>This page should retrieve <b>most recent</b> blogs (of any category), along with specified quantity determined by user.</li>
                                </ul>
                                <li><a href='/contact'>Contact</a></li>
                                <ul>
                                    <li>This page should allow user to <b>send message(s)</b> to the author of this site, along with carbon copy if requsted.</li>
                                </ul>
                            </ul>
                        </div>
                    </Collapse>

                    <div className="text-center about-title-font mt-3">
                        <Button
                            style={{ border: "double 4px #6c757d", borderRadius: "10px", padding: "8px 14px" }}
                            variant="outline-dark" onClick={() => { setNonFuncRequirementsOpen(!nonFuncRequirementsOpen) }}>
                            Non-Functional Requirements
                        </Button>
                    </div>
                    <Collapse in={nonFuncRequirementsOpen}>
                        <div style={{
                            marginTop: "1rem",
                            background: "rgba(255, 255, 255, .45)",
                            padding: "20px",
                            borderRadius: "20px",
                            fontFamily: 'Sour Gummy, sans-serif'
                        }}>
                            <ul>
                                <li><a href='/'>This App</a></li>
                                <ul>
                                    <li>Reliability</li>
                                    <ul>
                                        <li>The system shall have an <b>uptime of 99.99%</b> manually.</li>
                                    </ul>
                                    <li>Scalability</li>
                                    <ul>
                                        <li>The system shall support up to <b>20,000 concurrent users</b> without the degradation of performance.</li>
                                    </ul>
                                    <li>Availability</li>
                                    <ul>
                                        <li>The system shall be <b>available 24×7</b> excluding the maintenance window.</li>
                                    </ul>
                                    <li><a href='https://learn.microsoft.com/en-us/azure/static-web-apps/authentication-authorization' target="_blank" rel="noreferrer">Security</a></li>
                                    <ul>
                                        <li>The system shall <b>authenticate user</b> with a preconfigured provider.</li>
                                    </ul>
                                </ul>
                                <li><a href='/'>Home</a></li>
                                <ul>
                                    <li>User Experience</li>
                                    <ul>
                                        <li>Allow user to <b>change quotes and background</b> when clicking logo located at top-left corner.</li>
                                    </ul>
                                </ul>
                                <li><a href='/blogs/kafka'>BlogCategory</a></li>
                                <ul>
                                    <li>Performance</li>
                                    <ul>
                                        <li>This page should be able to retrieve blogs within specified categories <b>within 5 seconds</b>, otherwise display timeout!</li>
                                    </ul>
                                </ul>
                                <li>Individual Blog Page</li>
                                <ul>
                                    <li>User Experience</li>
                                    <ul>
                                        <li>If top image doesn't show up instantly, have an <b>animated placeholder</b> having same width and height with that of actual image, to prevent footer from appearing in the middle of the screen.</li>
                                    </ul>
                                </ul>
                                <li><a href='/latestblogs'>BlogsRecent</a></li>
                                <ul>
                                    <li>Usability</li>
                                    <ul>
                                        <li>Once the number of blogs to be retrieved is determined by the user and query button is clicked, <b>disable the input</b> until user clicks the button to start over.</li>
                                        <li>Define the <b>range</b> on behalf of the user to prevent unexpected performance degradation should user decide to select outrageous number.</li>
                                    </ul>
                                </ul>
                                <li><a href='/about'>About</a></li>
                                <ul>
                                    <li>User Experience</li>
                                    <ul>
                                        <li>If width is less than <b>769px</b>, display mountain-sky background image, with blue-themed attribution.</li>
                                        <li>If width is equal to or greater than <b>769px</b>, display wheat background image, with light-brown-themed attribution.</li>
                                    </ul>
                                    <li>Security</li>
                                    <ul>
                                        <li>Address the warning that says, <code>"One of your code blocks includes unescaped HTML" (XSS attack vector)</code>.</li>
                                    </ul>
                                </ul>
                            </ul>
                        </div>
                    </Collapse>

                    <div className="text-center about-title-font mt-3">
                        <Button
                            style={{ border: "double 4px #6c757d", borderRadius: "10px", padding: "8px 14px" }}
                            variant="outline-dark" onClick={() => { setAboutAppOpen(!aboutAppOpen) }}>
                            About the app
                        </Button>
                    </div>
                    <Collapse in={aboutAppOpen}>
                        <div style={{
                            marginTop: "1rem",
                            background: "rgba(255, 255, 255, .45)",
                            padding: "20px",
                            borderRadius: "20px"
                        }}>
                            {
                                aboutContent &&
                                <ReactMarkdownWithPlugins content={aboutContent}></ReactMarkdownWithPlugins>
                            }
                            {
                                !aboutContent &&
                                <>
                                    <div className="text-center">
                                        <div className='text-muted'><em>Loading the content from backend...</em></div>
                                        <div className="lds-ellipsis">
                                            <div>
                                            </div>
                                            <div>
                                            </div>
                                            <div>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </Collapse>
                </Container>

                <div className='about-footer'>
                    <div className="text-muted">
                        <div style={{ color: "white" }}>
                            <a style={{ textDecoration: "none", color: "white" }} href="/">Home</a> | <a style={{ textDecoration: "none", color: "white" }} href="/blogs">Blogs</a> | <a style={{ textDecoration: "none", color: "white" }} href="/contact">Contact</a>
                        </div>
                        <div style={{ color: "white" }}>&copy; 2023 JustinKim.dev</div>
                        <div id='freepik-attribution'>
                            <div className='mountain-sky-bg'>
                                <a style={{ textDecoration: "none", backgroundColor: "rgba(0, 0, 0, 0.5)", paddingInline: "10px", borderRadius: "10px", color: "white", fontWeight: "lighter", background: "aliceblue" }}
                                    href="https://www.freepik.com/free-photo/beautiful-shot-high-white-hilltops-mountains-covered-fog_7630706.htm#fromView=keyword&page=1&position=48&uuid=8340d278-1988-4f71-9433-55f92af43bbe&new_detail=true"><span className="text-muted small">Image by wirestock on Freepik</span>
                                </a>
                            </div>
                            <div className='branch-wheat-bg'>
                                <a style={{ textDecoration: "none", backgroundColor: "rgba(0, 0, 0, 0.25)", paddingInline: "10px", borderRadius: "10px", color: "white", fontWeight: "lighter", background: "antiquewhite" }}
                                    href="https://www.freepik.com/free-photo/closeup-focused-shot-branch-wheat-with-bright-background_8281717.htm#fromView=keyword&page=1&position=1&uuid=8340d278-1988-4f71-9433-55f92af43bbe&new_detail=true"><span className="text-muted small">Image by wirestock on Freepik</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;