import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import breaks from 'remark-breaks';
import emoji from "remark-emoji";
import math from 'remark-math';
import katex from 'rehype-katex';
import hljs from 'highlight.js';
import 'highlight.js/styles/stackoverflow-dark.css';

function ReactMarkdownWithPlugins({ content }) {
    function escapeHTML(str) {
        return str.replace(/[&<>"']/g, function (match) {
            switch (match) {
                case '&': return '&amp;';
                case '<': return '&lt;';
                case '>': return '&gt;';
                case '"': return '&quot;';
                case "'": return '&#39;';
                default: return match;
            }
        });
    }

    useEffect(() => {
        document.querySelectorAll('pre code').forEach((el) => {
            // Below three lines of code addresses the following: https://github.com/highlightjs/highlight.js/wiki/security
            let content = el.textContent;
            el.innerHTML = escapeHTML(el.innerHTML);
            el.textContent = content;

            hljs.highlightElement(el);
        });
    });

    let newContent = content.replaceAll('<br>', '&#10;') // treat user written <br> as newline

    return (
        <ReactMarkdown
            children={newContent}
            linkTarget="_blank"
            remarkPlugins={[[gfm, { singleTilde: false }], breaks, emoji, math]}
            rehypePlugins={[katex]}>
        </ReactMarkdown>
    );
}

export { ReactMarkdownWithPlugins };