import NavigationBar from '../navbar/NavigationBar';
import Container from 'react-bootstrap/Container';
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { getLastUpdatedByRelative } from "../shared/ContentUtility.js";
import { useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function BlogsRecent() {
    const FORM_PAGE_BUTTON_START_OVER = 'Start Over';
    const FORM_PAGE_BUTTON_NEXT = 'Retrieve';
    const [formPageSize, setFormPageSize] = useState('');
    const [formPageButton, setFormPageButton] = useState(FORM_PAGE_BUTTON_NEXT);
    const [formPageSizeValid, setFormPageSizeValid] = useState(true);
    const timeoutInMilliseconds = 5000;
    const [latestBlogsLoading, setLatestBlogsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [latestBlogs, setLatestBlogs] = useState(null);
    const [currentPage, setCurrentPage] = useState();
    const navigate = useNavigate();

    function navigateTo(idx, theBlog) {
        let slug = theBlog.formTitle.toLowerCase().replaceAll(' ', '_');
        let category = theBlog.formCategory;
        // let encodedSlug = encodeURIComponent(slug);

        var parser = document.createElement('a');
        let currLocRef = window.location.href;
        currLocRef = currLocRef.replace('latestblogs', 'blogs') + '/' + category;
        if (currLocRef.endsWith('/')) {
            parser.href = currLocRef + `${slug}`;
        } else {
            parser.href = currLocRef + `/${slug}`;
        }

        navigate(parser.pathname, {
            replace: true, state: {
                data: theBlog,
                lastUpdated: theBlog._ts
            }
        });
    }

    const fetchData = async () => {
        const timeoutId = setTimeout(() => {
            setErrorMessage(`Timeout! Back-end service took more than ${timeoutInMilliseconds / 1000} seconds.`);
            setLatestBlogsLoading(false);
        }, timeoutInMilliseconds);

        setLatestBlogsLoading(true);
        fetch(process.env.REACT_APP_BLOGS_API_URL + `?pagination=${formPageSize}&page=${currentPage}`)
            .then(response => {
                if (response.ok) {
                    response.text().then(value => {
                        let responseValue = JSON.parse(value);
                        console.log(responseValue)
                        if (responseValue) {
                            if (responseValue.resources) {
                                setLatestBlogs(responseValue.resources)
                            }

                            let nextPage = responseValue.nextPage;
                            if (nextPage === -1) {
                                setFormPageButton(FORM_PAGE_BUTTON_START_OVER);
                            }

                            let requestCharge = responseValue.requestCharge;
                            if (requestCharge) {
                                console.log(`requestCharge: ${requestCharge}`);
                            }

                            setCurrentPage(nextPage);
                        }
                    });
                } else if (response.status === 300) {
                    alert('Blogs might actually exist, but it was wrongly cached. Try resetting your browser.');
                    setLatestBlogs(null);
                } else {
                    setLatestBlogs(null);
                    response.text().then(value => {
                        setErrorMessage(value);
                    });
                }

                clearTimeout(timeoutId);
                setLatestBlogsLoading(false);
            })
            .catch(error => {
                setLatestBlogsLoading(false);
                clearTimeout(timeoutId);
                console.log(error)
            });
    }

    function performPagination() {
        setErrorMessage(null);

        if (formPageSize === '') {
            setErrorMessage('Please enter a number between 1 and 10, inclusive!');
            return;
        }

        if (formPageButton === FORM_PAGE_BUTTON_START_OVER) {
            setFormPageSize('');
            setFormPageButton(FORM_PAGE_BUTTON_NEXT);
            setLatestBlogs([]);
            setCurrentPage();
        } else {
            fetchData();
        }
    }

    const formPageSizeChange = (event) => {
        const value = event.target.value;

        let result;
        if (value !== '') {
            if (value < 1) {
                result = false;
            } else if (value > 10) {
                result = false;
            } else {
                result = true;
            }
        } else {
            result = false;
        }

        setFormPageSize(value);
        setFormPageSizeValid(result);
    }

    return (
        <>
            <div className="blogs-bg">
                <NavigationBar></NavigationBar>
                <Container style={{ marginTop: "1rem", marginBottom: "5rem" }}>
                    {
                        errorMessage &&
                        <Alert variant="warning" onClose={() => setErrorMessage(null)} dismissible style={{ borderRadius: '20px' }}>
                            <Alert.Heading>Oh snap! Something doesn't seem right...</Alert.Heading>
                            <p>
                                {errorMessage}
                            </p>
                        </Alert>
                    }
                    <ButtonToolbar className="mb-3 justify-content-md-center" aria-label="Toolbar with Button groups">
                        <InputGroup>
                            <InputGroup.Text id="btnGroupAddon">Top</InputGroup.Text>
                            <Form.Control
                                disabled={formPageButton === FORM_PAGE_BUTTON_START_OVER}
                                value={formPageSize}
                                onChange={formPageSizeChange}
                                type="number"
                                placeholder="Number from 1 to 10"
                                aria-label="Number input"
                                aria-describedby="btnGroupAddon"
                                isInvalid={!formPageSizeValid}
                            />
                            {
                                formPageSizeValid &&
                                <Button onClick={performPagination} variant="light" className='ms-3'>{formPageButton}</Button>
                            }
                            <Form.Control.Feedback type="invalid">
                                The number is not within the range of 1 to 10.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </ButtonToolbar>
                    {
                        latestBlogs &&
                        <>
                            <Row xs={1} md={2} lg={4} className="g-4">
                                {
                                    latestBlogs
                                        .map((theBlog, idx) => (
                                            <Col key={theBlog.id}>
                                                <Nav.Link onClick={navigateTo.bind(this, idx, theBlog)}>
                                                    <Card>
                                                        <Card.Img variant="top" src={`https://picsum.photos/700/350?random=${idx}`} />
                                                        <Card.Body>
                                                            <Card.Title>{theBlog.formTitle}</Card.Title>
                                                            <Card.Text>{theBlog.formContent && theBlog.formContent.slice(0, 100)}</Card.Text>
                                                            {
                                                                theBlog?.formTags?.map((tag, tagIdx) => (
                                                                    <Badge
                                                                        className="me-2"
                                                                        key={`tag-${tagIdx}`}
                                                                        pill
                                                                        bg="secondary">
                                                                        {tag.formTag}
                                                                    </Badge>
                                                                ))
                                                            }
                                                        </Card.Body>
                                                        <Card.Footer>
                                                            <small className="text-muted">{getLastUpdatedByRelative(theBlog._ts)}</small>
                                                        </Card.Footer>
                                                    </Card>
                                                </Nav.Link>
                                            </Col>
                                        ))
                                }
                            </Row>
                        </>
                    }
                    {
                        !latestBlogs &&
                        latestBlogsLoading === true &&
                        <>
                            <div className="centered">
                                <div className="centered font-cherry-bomb" style={{ color: 'white' }}>Loading...</div>
                                <div className="lds-circle"><div></div></div>
                            </div>
                        </>
                    }
                </Container>
            </div>
        </>
    );
}

export default BlogsRecent;