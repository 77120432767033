import './Blogs.css';
import './BlogCategory.css';
import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { getLastUpdatedByRelative } from "../shared/ContentUtility.js";
import NavigationBar from '../navbar/NavigationBar';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

function BlogCategory() {
    const timeoutInMilliseconds = 5000;
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);
    const [blogCategoryLoading, setBlogCategoryLoading] = useState(true);
    const [blogCategory, setBlogCategory] = useState(null);

    let params = useParams()
    let category = params.category

    useEffect(() => {
        const fetchData = async () => {
            const timeoutId = setTimeout(() => {
                setErrorMessage(`Timeout! Back-end service took more than ${timeoutInMilliseconds / 1000} seconds. However, if you wait 10 more seconds, it will likely to display the list. In cloud computing, there's something called cold-storage where retrieval can take some time for the first time.`);
                setBlogCategoryLoading(false);
            }, timeoutInMilliseconds);

            fetch(process.env.REACT_APP_BLOGS_API_URL + `?name=${category}`)
                .then(response => {
                    if (response.ok) {
                        response.text().then(value => {
                            let blogArray = JSON.parse(value);
                            setBlogCategory(blogArray);
                        });
                    } else if (response.status === 300) {
                        alert('Blogs might actually exist, but it was wrongly cached. Try resetting your browser.');
                        setBlogCategory(null);
                    } else {
                        setBlogCategory(null);
                    }

                    clearTimeout(timeoutId);
                    setBlogCategoryLoading(false);
                })
                .catch(error => {
                    clearTimeout(timeoutId);
                    console.log(error)
                });
        }
        fetchData();
    }, [category]);

    function navigateTo(idx, theBlog) {
        let slug = theBlog.formTitle.toLowerCase().replaceAll(' ', '_');
        // let encodedSlug = encodeURIComponent(slug);

        var parser = document.createElement('a');
        let currLocRef = window.location.href;
        if (currLocRef.endsWith('/')) {
            parser.href = currLocRef + `${slug}`;
        } else {
            parser.href = currLocRef + `/${slug}`;
        }

        navigate(parser.pathname, {
            replace: true, state: {
                data: theBlog,
                lastUpdated: theBlog._ts
            }
        });
    }

    const [blogSearchTag, setBlogSearchTag] = useState("");
    const blogSearchTagRef = useRef();
    const blogSearchTagOnClick = () => {
        setBlogSearchTag("");
        blogSearchTagRef.current.value = "";
    }

    return (
        <>

            <div className="blogs-bg">
                <NavigationBar></NavigationBar>
                <Container style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    {
                        errorMessage &&
                        <Alert variant="warning" onClose={() => setErrorMessage(null)} dismissible style={{ borderRadius: '20px' }}>
                            <Alert.Heading>Oh snap! Fast is my name, but...</Alert.Heading>
                            <p>
                                {errorMessage}
                            </p>
                        </Alert>
                    }
                    {
                        blogCategory &&
                        <>
                            <Form className="d-flex mb-4">
                                <Form.Control
                                    type="search"
                                    placeholder="Search by tag"
                                    className="me-2"
                                    aria-label="Search"
                                    ref={blogSearchTagRef}
                                    onChange={(event) => setBlogSearchTag(event.target.value)}
                                />
                                <Button variant="secondary" onClick={blogSearchTagOnClick.bind(this)}>Clear</Button>
                            </Form>
                            <Row xs={1} md={2} lg={4} className="g-4">
                                {
                                    blogCategory
                                        .filter(theBlog => {
                                            if (blogSearchTag !== "") {
                                                let filtered = theBlog?.formTags?.filter((tags) => tags.formTag === blogSearchTag);
                                                return filtered?.length > 0;
                                            } else {
                                                return true;
                                            }
                                        })
                                        .map((theBlog, idx) => (
                                            <Col key={theBlog.id}>
                                                <Nav.Link onClick={navigateTo.bind(this, idx, theBlog)}>
                                                    <Card>
                                                        <Card.Img variant="top" src={`https://picsum.photos/700/350?random=${idx}`} />
                                                        <Card.Body>
                                                            <Card.Title>{theBlog.formTitle}</Card.Title>
                                                            <Card.Text>{theBlog.formContent && theBlog.formContent.slice(0, 100)}</Card.Text>
                                                            {
                                                                theBlog?.formTags?.map((tag, tagIdx) => (
                                                                    <Badge
                                                                        className="me-2"
                                                                        key={`tag-${tagIdx}`}
                                                                        pill
                                                                        bg="secondary">
                                                                        {tag.formTag}
                                                                    </Badge>
                                                                ))
                                                            }
                                                        </Card.Body>
                                                        <Card.Footer>
                                                            <small className="text-muted">{getLastUpdatedByRelative(theBlog._ts)}</small>
                                                        </Card.Footer>
                                                    </Card>
                                                </Nav.Link>
                                            </Col>
                                        ))
                                }
                            </Row>
                        </>
                    }
                    {
                        !blogCategory &&
                        blogCategoryLoading === true &&
                        <>
                            <div className="centered">
                                <div className="centered font-cherry-bomb" style={{ color: 'white' }}>Loading...</div>
                                <div className="lds-circle"><div></div></div>
                            </div>
                        </>
                    }
                    {
                        !blogCategory &&
                        blogCategoryLoading === false &&
                        <div className="centered">
                            <Card>
                                <Card.Body>
                                    <Card.Title
                                        className='font-cherry-bomb'
                                        style={{ textAlign: "center" }}>
                                        No blogs found...
                                    </Card.Title>
                                </Card.Body>
                                <Card.Img
                                    style={{
                                        borderBottomLeftRadius: "0",
                                        borderBottomRightRadius: "0",
                                        borderTopLeftRadius: "0",
                                        borderTopRightRadius: "0"
                                    }}
                                    src={'https://picsum.photos/800/400'} />
                                {
                                    !errorMessage &&
                                    <Card.Body>
                                        <Card.Text
                                            className='font-courgette'
                                            style={{ textAlign: "center" }}>
                                            But you can add one!
                                        </Card.Text>
                                    </Card.Body>
                                }
                            </Card>
                        </div>
                    }
                </Container>
            </div>
        </>
    )
}

export default BlogCategory;